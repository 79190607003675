<template>
  <div id="app">
    <router-view v-if="is_loaded" />

    <notifications group="foo" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.init()
  },

  data() {
    return {
      is_loaded: false
    }
  },

  methods: {
    init() {
      this.$axios.get('/api/v1/init').then(response => {
        this.is_loaded = true
        if(response.data.authenticated) {
          this.$auth.setPermissions(response.data.user.role.permissions)
          this.$store.dispatch('setAuthUser', response.data.user)

          if(! this.$auth.hasPermissions()) {
            window.location.reload(true)
          }
          
          
          if(this.$route.meta.guest) {
            window.location.href = this.$router.resolve({ name: 'dashboard' }).href
          }
        } else {
          if(this.$auth.hasToken()) {
            this.$auth.removeToken()
            this.$auth.removePermissions()
            window.location.href = this.$router.resolve({ name: 'login' }).href
          }

          if(! this.$route.meta.guest) {
            window.location.href = this.$router.resolve({ name: 'login' }).href
          }
        }
      })
    }
  }
}
</script>
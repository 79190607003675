<template>
  <div class="page-header">
    <slot name="button"></slot>
    <h3>{{ title }}</h3>
    <nav aria-label="breadcrumb" class="breadcrumbs">
      <ol class="breadcrumb">
        <template v-for="(crumb, i) in breadcrumbs">
          <li :class="`breadcrumb-item ${(i+1) == breadcrumbs.length ? 'active' : ''}`" :key="`crumb-${i}`">
            <router-link :to="{ name: 'dashboard' }" v-if="(i+1) < breadcrumbs.length">{{ crumb }}</router-link>
            <template v-if="(i+1) == breadcrumbs.length">{{ crumb }}</template>
          </li>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    }
  },

  computed: {
    breadcrumbs() {
      return this.$route.fullPath.split('?')[0].split('/').filter(crumb => {
        return crumb != '';
      }).map(crumb => {
        let new_crumb = crumb.replace('-', ' ');

        return new_crumb;
      });
    }
  }
};
</script>
<template>
  
  
    <div class="showquote quote shadow" v-if="showQuote" @click="closeModal">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            :class="`nav-link ${tab == 1 ? 'active' : ''}`"
            href="#"
            @click.prevent="selectStep(1)"
            >Bid Bond Quote</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="`nav-link ${tab == 2 ? 'active' : ''}`"
            href="#"
            @click.prevent="selectStep(2)"
            >Performance Bond Quote</a
          >
        </li>
      </ul>
      <div class="tab-content mb-4" v-if="step == 1">
        <template v-if="!loading_quote">
          <transition name="fade">
            <div v-if="tab == 1">
              <form @submit.prevent="submit">
                <p class="intro">
                  Fill in this form and we'll have your quote ready in a few
                  moments.
                </p> 
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="bidbond_quote.email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Phone Number</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bidbond_quote.phone_number"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tenure Duration</label>
                      <vue-select
                        :options="tenure_durations"
                        label="name"
                        placeholder="Select Tenure Duration"
                        v-model="tenure_duration"
                        required="required"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!tenure_duration"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </vue-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Procuring Entity</label>
                      <vue-select
                        :options="counterparties"
                        label="name"
                        placeholder="Select counterparty"
                        v-model="counter_party"
                        required="required"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!counter_party"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </vue-select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Bid Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bidbond_quote.amount"
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="btn btn-secondary btn-block">
                    Request Quote
                  </button>
                </div>
              </form>
            </div>
          </transition>
          <transition name="fade">
            <div v-if="tab == 2">
              <form @submit.prevent="submitPerformancebond">
                <p class="intro">
                  Fill in this form and we'll have your quote ready in a few
                  moments.
                </p>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="bidbond_quote.email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Phone Number</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bidbond_quote.phone_number"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tenure Duration</label>
                      <vue-select
                        :options="tenure_durations"
                        label="name"
                        placeholder="Select Tenure Duration"
                        v-model="tenure_duration"
                        required="required"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!tenure_duration"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </vue-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Procuring Entity</label>
                      <vue-select
                        :options="counterparties"
                        label="name"
                        placeholder="Select counterparty"
                        v-model="counter_party"
                        required="required"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!counter_party"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </vue-select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Bid Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bidbond_quote.amount"
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="btn btn-secondary btn-block">
                    Request Quote
                  </button>
                </div>
              </form>
            </div>
          </transition>
        </template>
        <div class="quote-loading" v-if="loading_quote">
          <div class="spinner-border"></div>
        </div>
      </div>
      <div class="tab-content shadow mb-4" v-if="step == 2">
        <template v-if="the_quote.status">
          <h4>
            Your quote for
            {{ $number.format(bidbond_quote.amount) }} is
          </h4>
          <table class="table ">
            <tbody>
              <tr>
                <td>Charge</td>
                <td>
                  KES
                  {{
                    $number.format(
                      tab == 1
                        ? the_quote.bid_bond_charge
                        : the_quote.performancebond_charge
                    )
                  }}
                </td>
              </tr>
              <tr v-if="tab == 2">
                <td>Cash Cover</td>
                <td>
                  KES
                  {{ $number.format(the_quote.cash_cover) }}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>
                  <strong>KES {{ $number.format(the_quote.total) }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="alert alert-success mt-3">
            We have also sent you an email with the quote.
          </div>
        </template>
        <template v-if="!the_quote.status">
          <div class="alert alert-danger">
            The amount KES
            {{ $number.format(bidbond_quote.amount) }} is out of scope. Kindly try
            another amount.
          </div>
        </template>
        <div class="mt-2 text-center">
          <a href="#" class="btn btn-secondary" @click.prevent="selectStep(tab)"
            >OKAY</a
          >
        </div>
      </div>
    </div>
  

</template>


<script>
import tenure_periods from "@/data/tenure-periods";


export default {
  mounted() {
    this.fetch();
  },

  props: ['quote'],

  watch: {
    quote: function (val) {


      if(val == false){
        this.showQuote = false;
      }
      if (val) {
        this.bidbond_quote.amount = val.amount;
        this.bidbond_quote.email = val.email;
        this.bidbond_quote.phone_number = val.phone_number;
        this.counter_party = val.counterparty;
        this.tenure_duration = this.tenure_durations.find(
          (x) => x.value == val.tenure
        );
        this.openModal();
      }
    },
  },

  data() {
    return {
      tab: 1,
      counterparties: [],
      bidbond_quote: {
        phone_number: 254,
      },
      counter_party: "",
      tenure_duration: "",
      tenure_durations: tenure_periods.data,
      step: 1,
      loading_quote: false,
      the_quote: {},
      showQuote: false,

    };
  },
  
  methods: {
    fetch() {
      this.$axios.get("/api/v1/quote/create").then((response) => {
        this.counterparties = response.data;
      });
    },
    closeModal(event ) {
      if (event.target.classList.contains("showquote")) {

        this.$emit('close-modal');

      }

    },

    selectStep(step) {
      this.tab = 0;
      this.step = 1;
      setTimeout(() => {
        this.tab = step;
      }, 500);
    },
    openModal() {
    this.showQuote = true;
    },

    submit() {
      let data = {
        amount: this.bidbond_quote.amount,
        counterparty: this.counter_party.id,
        email: this.bidbond_quote.email,
        tenure: this.tenure_duration.value,
        type: "bidbond",
        phone_number: this.bidbond_quote.phone_number,
      };
      this.loading_quote = true;

      this.$axios
        .post("/api/v1/quote", data)
        .then((response) => {
          this.step = 2;
          this.loading_quote = false;
          this.the_quote = response.data;
        })
        .catch((error) => {
          this.$http(error.response);
        });
    },

    submitPerformancebond() {
      let data = {
        amount: this.bidbond_quote.amount,
        counterparty: this.counter_party.id,
        email: this.bidbond_quote.email,
        tenure: this.tenure_duration.value,
        type: "performancebond",
        phone_number: this.bidbond_quote.phone_number,
      };
      this.loading_quote = true;

      this.$axios
        .post("/api/v1/quote", data)
        .then((response) => {
          this.step = 2;
          this.loading_quote = false;
          this.the_quote = response.data;
          this.bidbond_quote = {
            phone_number: 254,
          };
        })
        .catch((error) => {
          this.$http(error.response);
        });
    },
  },
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Index.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/recover-account/:token',
    name: 'recover-account',
    component: () => import(/* webpackChunkName: "recover-account" */ '../views/auth/RecoverAccount.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/dashboard/Index.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/dashboard/Settings.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/dashboard/profile/Profile.vue')
      },
      {
        path: 'profile/security',
        name: 'profile.security',
        component: () => import(/* webpackChunkName: "profile.security" */ '../views/dashboard/profile/Security.vue')
      },
      {
        path: 'counterparties',
        component: () => import(/* webpackChunkName: "counterparties" */ '../views/dashboard/CounterParties.vue'),
        children: [
          {
            path: '',
            name: 'counterparties.index',
            component: () => import(/* webpackChunkName: "counterparties.index" */ '../views/dashboard/counterparties/Index.vue')
          },
          {
            path: 'create',
            name: 'counterparties.create',
            component: () => import(/* webpackChunkName: "counterparties.create" */ '../views/dashboard/counterparties/Create.vue')
          },
          {
            path: ':id',
            name: 'counterparties.edit',
            component: () => import(/* webpackChunkName: "counterparties.edit" */ '../views/dashboard/counterparties/Edit.vue')
          }
        ]
      },
      {
        path: 'bidbond-pricing',
        component: () => import(/* webpackChunkName: "bidbond-pricing" */ '../views/dashboard/BidbondPricing.vue'),
        children: [
          {
            name: 'bidbond-pricing.index',
            path: '',
            component: () => import(/* webpackChunkName: "bidbond-pricing.index" */ '../views/dashboard/bidbond-pricing/Index.vue')
          },
          {
            name: 'bidbond-pricing.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "bidbond-pricing.create" */ '../views/dashboard/bidbond-pricing/Create.vue')
          }
        ]
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/dashboard/Users.vue'),
        children: [
          {
            name: 'users.index',
            path: '',
            component: () => import(/* webpackChunkName: "users.index" */ '../views/dashboard/users/Index.vue')
          },
          {
            name: 'users.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "users.create" */ '../views/dashboard/users/Create.vue')
          },
          {
            name: 'users.show',
            path: ':id',
            component: () => import(/* webpackChunkName: "users.show" */ '../views/dashboard/users/Show.vue')
          }
        ]
      },
      {
        path: 'bond-templates',
        component: () => import(/* webpackChunkName: "bond-templates" */ '../views/dashboard/BondTemplates.vue'),
        children: [
          {
            name: 'bond-templates.index',
            path: '',
            component: () => import(/* webpackChunkName: "bond-templates.index" */ '../views/dashboard/bond-templates/Index.vue')
          },
          {
            name: 'bond-templates.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "bond-templates.create" */ '../views/dashboard/bond-templates/Create.vue')
          },
          {
            name: 'bond-templates.show',
            path: ':id',
            component: () => import(/* webpackChunkName: "bond-templates.show" */ '../views/dashboard/bond-templates/Show.vue')
          },
          {
            name: 'bond-templates.edit',
            path: ':id/edit',
            component: () => import(/* webpackChunkName: "bond-templates.show" */ '../views/dashboard/bond-templates/Edit.vue')
          }
        ]
      },
      {
        path: 'performancebond-pricing',
        component: () => import(/* webpackChunkName: "performancebond-pricing" */ '../views/dashboard/PerformancebondPricing.vue'),
        children: [
          {
            name: 'performancebond-pricing.index',
            path: '',
            component: () => import(/* webpackChunkName: "performancebond-pricing.index" */ '../views/dashboard/performancebond-pricing/Index.vue')
          },
          {
            name: 'performancebond-pricing.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "performancebond-pricing.create" */ '../views/dashboard/performancebond-pricing/Create.vue')
          }
        ]
      },
      {
        path: "roles",
        component: () => import(/* webpackChunkName: "roles" */ "../views/dashboard/Roles.vue"),
        children: [
          {
            path: "",
            name: "roles.index",
            component: () => import(/* webpackChunkName: "roles.index" */ "../views/dashboard/roles/Index.vue"),
          },
          {
            path: "create",
            name: "roles.create",
            component: () => import(/* webpackChunkName: "roles.create" */ "../views/dashboard/roles/Create.vue"),
          },
          {
            path: ":id",
            name: "roles.show",
            component: () => import(/* webpackChunkName: "roles.show" */ "../views/dashboard/roles/Show.vue"),
          },
          {
            path: ":id/edit",
            name: "roles.edit",
            component: () => import(/* webpackChunkName: "roles.edit" */ "../views/dashboard/roles/Edit.vue"),
          }
        ]
      },
      {
        path: 'companies',
        component: () => import(/* webpackChunkName: "companies" */ '../views/dashboard/Companies.vue'),
        children: [
          {
            name: 'companies.index',
            path: '',
            component: () => import(/* webpackChunkName: "companies.index" */ '../views/dashboard/companies/Index.vue')
          },
          {
            name: 'companies.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "companies.create" */ '../views/dashboard/companies/Create.vue')
          },
          {
            name: 'companies.show',
            path: ':id',
            component: () => import(/* webpackChunkName: "companies.show" */ '../views/dashboard/companies/Show.vue')
          },
          {
            name: 'companies.edit',
            path: ':id/edit',
            component: () => import(/* webpackChunkName: "companies.edit" */ '../views/dashboard/companies/Edit.vue')
          },
        ]
      },
      {
        path: 'banks',
        component: () => import(/* webpackChunkName: "banks" */ '../views/dashboard/Banks.vue'),
        children: [
          {
            name: 'banks.index',
            path: '',
            component: () => import(/* webpackChunkName: "banks.index" */ '../views/dashboard/banks/Index.vue')
          },
          
          {
            name: 'banks.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "banks.create" */ '../views/dashboard/banks/Create.vue')
          },

          {
            name: 'banks.show',
            path: ':id',
            component: () => import(/* webpackChunkName: "banks.show" */ '../views/dashboard/banks/Show.vue')
          },
          {
            name: 'banks.edit',
            path: ':id/edit',
            component: () => import(/* webpackChunkName: "banks.edit" */ '../views/dashboard/banks/Edit.vue')
          },
        ]
      },
      {
        path: 'bidbonds',
        component: () => import(/* webpackChunkName: "bidbonds" */ '../views/dashboard/Bidbonds.vue'),
        children: [
          {
            name: 'bidbonds.index',
            path: '',
            component: () => import(/* webpackChunkName: "bidbonds.index" */ '../views/dashboard/bidbonds/Index.vue')
          },
          {
            name: 'bidbonds.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "bidbonds.create" */ '../views/dashboard/bidbonds/Create.vue')
          },
          {
            name: 'bidbonds.show',
            path: ':id',
            component: () => import(/* webpackChunkName: "bidbonds.show" */ '../views/dashboard/bidbonds/Show.vue')
          },
          {
            name: 'bidbonds.payment',
            path: ':id/payment',
            component: () => import(/* webpackChunkName: "bidbonds.payment" */ '../views/dashboard/bidbonds/Payment.vue')
          }
        ]
      },
      {
        path: 'performancebonds',
        component: () => import(/* webpackChunkName: "performancebonds" */ '../views/dashboard/Performancebonds.vue'),
        children: [
          {
            name: 'performancebonds.index',
            path: '',
            component: () => import(/* webpackChunkName: "performancebonds.index" */ '../views/dashboard/performancebonds/Index.vue')
          },
          {
            name: 'performancebonds.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "performancebonds.create" */ '../views/dashboard/performancebonds/Create.vue')
          },
          {
            name: 'performancebonds.show',
            path: ':id',
            component: () => import(/* webpackChunkName: "performancebonds.show" */ '../views/dashboard/performancebonds/Show.vue')
          },
          {
            name: 'performancebonds.payment',
            path: ':id/payment',
            component: () => import(/* webpackChunkName: "performancebonds.payment" */ '../views/dashboard/performancebonds/Payment.vue')
          }
        ]
      },
      {
        path: 'quotes',
        component: () => import(/* webpackChunkName: "quotes" */ '../views/dashboard/Quotes.vue'),
        children: [
          {
            name: 'quotes.index',
            path: '',
            component: () => import(/* webpackChunkName: "quotes.index" */ '../views/dashboard/quotes/Index.vue')
          },
          {
            name: 'quotes.create',
            path: 'create',
            component: () => import(/* webpackChunkName: "quotes.create" */ '../views/dashboard/quotes/Create.vue')
          }
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
		return { x: 0, y: 0 };
	},
  routes
})

export default router

export default {
  data: [
    {
      name: "30 days",
      value: 30
    },
    {
      name: "60 days",
      value: 60
    },
    {
      name: "90 days",
      value: 90
    },
    {
      name: "120 days",
      value: 120
    },
    {
      name: "150 days",
      value: 150
    },
    {
      name: "180 days",
      value: 180
    },
    {
      name: "210 days",
      value: 210
    },
    {
      name: "365 days",
      value: 365
    }
  ]
}
import moment from 'moment'

export default {
  install(Vue) {
    window.moment = moment;

    Vue.moment = {
      format(date) {
        if (moment(date).isValid()) {
          return moment(date).format('YYYY-MM-DD');
        }

        return '';
      },

      moment(data) {
        return moment(data);
      },

      parse(data) {
        return moment(data);
      },

      read(date) {
        if (moment(date).isValid()) {
          return moment(date).format('dddd Do MMMM YYYY');
        }

        return 'Invalid Date';
      },
    };

    Object.defineProperties(Vue.prototype, {
      $moment: {
        get() {
          return Vue.moment;
        },
      },
    });
  },
};

<template>
  <div class="footer-dark">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-3 item">
            <ul>
              <li><a href="#">info@mtenders.co.ke</a></li>
              <li><a href="#">0795717622</a></li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-3 item">
            <ul>
              <li><a href="#">About Mtenders</a></li>
              <li><a href="#">Mtenders Listings</a></li>
            </ul>
          </div>
          <div class="col-md-6 item text">
            <!-- <h3>Mtenders partner</h3>
            <img src="/family-bank.png" alt="family bank" height="20px"> -->
          </div>
        </div>
        <p class="copyright my-1">Mtenders © 2020</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

/*eslint-disable*/
import $ from 'jquery'

$(document).ready(function() {
  $(".Modern-Slider").slick({
    autoplay: !0,
    autoplaySpeed: 5e3,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: !1,
    dots: !0,
    pauseOnDotsHover: !0,
    cssEase: "linear",
    fade: !0,
    draggable: !1,
    prevArrow:
      '<button class="PrevArrow"> <span class="Thumbnail"></span></button>',
    nextArrow:
      '<button class="NextArrow"> <span class="Thumbnail"></span></button>',
    customPaging: function(r, e) {
      return (
        '<a href="javascript:void(0)"><img src="' +
        $(r.$slides[e]).data("thumbnail") +
        '"></a>'
      );
    },
  });
  var r = $(".Modern-Slider .item:last-child")
    .children(".img-fill")
    .children("img")
    .attr("src");
  $(".Modern-Slider .PrevArrow .Thumbnail").css({
    "background-image": "url(" + r + ")",
  });
  var e = $(".Modern-Slider .item:first-child")
    .next()
    .children(".img-fill")
    .children("img")
    .attr("src");
  $(".Modern-Slider .NextArrow .Thumbnail").css({
    "background-image": "url(" + e + ")",
  }),
    $(".Modern-Slider").on("afterChange", function(r, e, i) {
      var n = $(".Modern-Slider .item.slick-active")
        .prev(".item")
        .children(".img-fill")
        .children("img")
        .attr("src");
      $(".Modern-Slider .PrevArrow .Thumbnail").css({
        "background-image": "url(" + n + ")",
      });
      var a = $(".Modern-Slider .item.slick-active")
        .next(".item")
        .children(".img-fill")
        .children("img")
        .attr("src");
      $(".Modern-Slider .NextArrow .Thumbnail").css({
        "background-image": "url(" + a + ")",
      });
    });
}),
  $(window).scroll(function() {
    $(window).scrollTop() >= 50
      ? $(".navbar").css("background", "rgba(0,0,0,0.61)")
      : $(".navbar").css("background", "rgba(0,0,0,0.61");
  }),
  $(".header-nav").click(function() {
    return $(".hidden-div").toggle(), $(this).toggleClass("block"), !1;
  });

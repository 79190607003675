<template>
  <div class="text-center loader-component">
    <!-- <img src="./loading.gif" alt="loading"> -->
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
img {
  margin-left: auto;
  margin-right: auto;
  height: 150px !important;
  width: 150px !important;
}


</style>
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.config.productionTip = false
const options = {
  // You can set your default options here
};

Vue.use(Toast, options);

// plugins
import Auth from './plugins/auth'
Vue.use(Auth)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import Http from './plugins/http-handler'
Vue.use(Http)

import Numeral from "./plugins/numeral"
Vue.use(Numeral)

import Permissions from "./plugins/permissions"
Vue.use(Permissions)

import Loader from './plugins/loader'
Vue.use(Loader)

import VueNumeric from "vue-numeric";
Vue.use(VueNumeric);

import Moment from "./plugins/moment";
Vue.use(Moment);

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput, [{}])

Vue.directive('click-outside',{
  bind: function (el, binding, vnode) {
      el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes');
      }
      el.eventClearDrag = function () {
          el.removeAttribute('data-dragging');
      }
      el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging');
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
              // call method provided in attribute value
              vnode.context[binding.expression](event);
          }
      };
      document.addEventListener('touchstart', el.eventClearDrag);
      document.addEventListener('touchmove', el.eventSetDrag);
      document.addEventListener('click', el.eventOnClick);
      document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
      document.removeEventListener('touchstart', el.eventClearDrag);
      document.removeEventListener('touchmove', el.eventSetDrag);
      document.removeEventListener('click', el.eventOnClick);
      document.removeEventListener('touchend', el.eventOnClick);
      el.removeAttribute('data-dragging');
  },
});

// Components
import PageTitle from "./components/dashboard/PageTitle"
Vue.component("page-title", PageTitle)

import Modal from "./components/Modal.vue"
Vue.component("modal", Modal)

import VueSelect from "vue-select"
Vue.component("vue-select", VueSelect)

import Chart from "./components/dashboard/Chart"
Vue.component("chart", Chart)

import { Hooper, Slide } from 'hooper'
Vue.component('hooper', Hooper)
Vue.component('slide', Slide)

import GuestNavbar from './components/GuestNavbar'
Vue.component('guest-navbar', GuestNavbar)

import GuestFooter from './components/GuestFooter'
Vue.component('guest-footer', GuestFooter)

import GuestSlider from './components/GuestSlider'
Vue.component('guest-slider', GuestSlider)

import GuestQuote from './components/GuestQuote'
Vue.component('guest-quote', GuestQuote)

import './theme'

router.afterEach(() => {
  if(document.getElementById('error-page')) {
    window.location.reload(true)
  }
})

let app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Add a response interceptor
app.$axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    let the_error = error.response

    if(the_error == null) {
      return Promise.reject(error);
    }

    if(the_error.status == 403) {
      app.$serverError(the_error)
    }

    return Promise.reject(error);
  }
);


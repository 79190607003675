import LoaderComponent from './LoaderComponent'
import './style.scss'

export default {
  install(Vue) {
    Vue.component('loader', LoaderComponent)

    Vue.loader = {
      start() {
        let overlay = document.createElement('div')
        overlay.id = 'page-loader'

        let loader = document.createElement('div')
        loader.classList.add('spinner-border')

        overlay.appendChild(loader)

        document.body.appendChild(overlay)
      },

      stop() {
        let el = document.getElementById('page-loader')

        if(el) {
          el.remove()
        }
      }
    };

    Object.defineProperties(Vue.prototype, {
      $loader: {
        get() {
          return Vue.loader;
        }
      }
    });
  }
};

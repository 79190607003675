export default {
  install(Vue) {
    Vue.can = function(permission) {
      let user_permissions = Vue.auth.getPermissions()

      return user_permissions.indexOf(permission) > -1
    }

    Vue.canAny = function(permission) {
      let user_permissions = Vue.auth.getPermissions()

      return user_permissions.indexOf(`${permission}.view`) > -1 || user_permissions.indexOf(`${permission}.store`) > -1 || user_permissions.indexOf(`${permission}.update`) > -1 || user_permissions.indexOf(`${permission}.destroy`) > -1
    }

    Object.defineProperties(Vue.prototype, {
      $can: {
        get() {
          return Vue.can;
        }
      }
    });

    Object.defineProperties(Vue.prototype, {
      $canAny: {
        get() {
          return Vue.canAny;
        }
      }
    });
  }
};

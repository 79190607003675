<template>
  <nav
    class="navbar navbar-light navbar-expand-md navigation-clean-button fixed-top"
  >
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand" href="#"
        ><img class="img-fluid" src="/logo.jpg"/></router-link>
        <button
        data-toggle="collapse"
        class="navbar-toggler"
        data-target="#navcol-1"
      >
        <span class="sr-only">Toggle navigation</span
        ><span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navcol-1">
        <ul class="nav navbar-nav mr-auto"></ul>
        <router-link :to="{ name: 'login' }" class="btn mx-1 text-white" role="button">Login</router-link>
        <router-link :to="{ name: 'register' }" class="btn mx-1 text-white" role="button">Create Account</router-link>
        <button class="btn btn-primary ml-auto header-nav" type="button">
          Request Quote</button
        ><span class="navbar-text actions"> </span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  
}
</script>
var handle422 = function(data, element) {
  let form = document.getElementById(element)

  if(form == null) {
    return false
  }

  let inputs = Object.values(form.getElementsByTagName('input'))
  let textareas = Object.values(form.getElementsByTagName('textarea'))
  let selects = Object.values(form.getElementsByTagName('select'))

  let all_inputs = inputs.concat(textareas).concat(selects)
  let inputs_with_errors = Object.keys(data.errors)

  all_inputs.forEach(inpt => {
    if(inputs_with_errors.indexOf(inpt.name) >= 0) {
      inpt.classList.add('is-invalid')

      let span_error = document.createElement('div')
      span_error.classList.add('invalid-feedback')
      span_error.innerHTML = data.errors[inpt.name].join()
      inpt.parentNode.append(span_error)
    }
  })
}

import Error403 from '@/views/dashboard/errors/403.js'
var handle403 = function() {
  document.getElementById('main-content').innerHTML = Error403
}

export default {
  install(Vue) {
    Vue.http = function(response, element = null) {
      if(response == null) {
        return false;
      }

      switch (response.status) {
        case 422:
          return handle422(response.data, element)

        default:
          return false
      }
    }

    Vue.serverError = function(response) {
      if(response == null) {
        return false;
      }

      switch (response.status) {
        case 403:
          return handle403()

        default:
          return false
      }
    }

    Object.defineProperties(Vue.prototype, {
      $http: {
        get() {
          return Vue.http;
        }
      }
    })

    Object.defineProperties(Vue.prototype, {
      $serverError: {
        get() {
          return Vue.serverError;
        }
      }
    })
  }
}
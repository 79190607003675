<template>
  <div>
    <div class="Modern-Slider">
      <div class="item">
        <div class="img-fill">
          <img src="/slider/1.jpg" />
          <div class="info">
            <div>
              <h3>
                When deadline strikes and <br />
                your deaedline is in one hour
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="img-fill">
          <img src="/slider/2.jpg" />
          <div class="info">
            <div>
              <h3>
                And you Don't have a <br />
                Bid security yet
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="img-fill">
          <img src="/slider/3.jpg" />
          <div class="info">
            <div>
              <h3>
                You are sorted with <br />
                affordable rates
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="img-fill">
          <img src="/slider/4.jpg" />
          <div class="info">
            <div>
              <h3>Apply now!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="guest-content">
      <div class="container-fluid">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import $ from "jquery";

export default {
  mounted() {
    this.slide();
  },

  methods: {
    slide() {
      $(document).ready(function() {
        $(".Modern-Slider").slick({
          autoplay: !0,
          autoplaySpeed: 5e3,
          speed: 1500,
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover: !1,
          dots: !0,
          pauseOnDotsHover: !0,
          cssEase: "linear",
          fade: !0,
          draggable: !1,
          prevArrow:false,
          nextArrow:false,
          customPaging: function(r, e) {
            return (
              '<a href="javascript:void(0)"><img src="' +
              $(r.$slides[e]).data("thumbnail") +
              '"></a>'
            );
          },
        });
        var r = $(".Modern-Slider .item:last-child")
          .children(".img-fill")
          .children("img")
          .attr("src");
        $(".Modern-Slider .PrevArrow .Thumbnail").css({
          "background-image": "url(" + r + ")",
        });
        var e = $(".Modern-Slider .item:first-child")
          .next()
          .children(".img-fill")
          .children("img")
          .attr("src");
        $(".Modern-Slider .NextArrow .Thumbnail").css({
          "background-image": "url(" + e + ")",
        }),
          $(".Modern-Slider").on("afterChange", function(r, e, i) {
            var n = $(".Modern-Slider .item.slick-active")
              .prev(".item")
              .children(".img-fill")
              .children("img")
              .attr("src");
            // $(".Modern-Slider .PrevArrow .Thumbnail").css({
            //   "background-image": "url(" + n + ")",
            // });
            var a = $(".Modern-Slider .item.slick-active")
              .next(".item")
              .children(".img-fill")
              .children("img")
              .attr("src");
            // $(".Modern-Slider .NextArrow .Thumbnail").css({
            //   "background-image": "url(" + a + ")",
            // });
          });
      }),
        $(window).scroll(function() {
          $(window).scrollTop() >= 50
            ? $(".navbar").css("background", "rgba(0,0,0,0.61)")
            : $(".navbar").css("background", "rgba(0,0,0,0.61");
        }),
        $(".header-nav").click(function() {
          $(".guest-content").toggle(), !1;
          return $(".hidden-div").toggle(), $(this).toggleClass("block"), !1;
        });
         $(".router-link-exact-active").click(function() {
          $(".guest-content").toggle(), !1;
          return $(".hidden-div").toggle(), $(this).toggleClass("block"), !1;
        });

    },
  },
};
</script>

import './assets/fonts/lineicons/LineIcons.scss'

import './assets/scss/main.scss'

import 'vue-select/dist/vue-select.css'

import './assets/theme/css/datatables.css'

import 'hooper/dist/hooper.css';

import './assets/css/styles.css';

window.$ = window.jQuery = require('jquery')
require('popper.js')
require('bootstrap')
require('./assets/theme/js/datatables')
require('./assets/theme/js/datatables-bootstrap')
require('./assets/theme/js/front-end')